<script setup lang="ts">
type Variant = 'text' | 'circular' | 'rectangular' | 'rounded';
type Size = number | string;
type Theme = 'dark' | 'light';

type Props = {
  as?: string;
  variant?: Variant;
  width?: Size;
  height?: Size;
  theme: Theme;
  styles?: Object;
};

const props = withDefaults(defineProps<Props>(), {
  as: 'span',
  variant: 'rectangular',
  width: undefined,
  height: undefined,
  styles: undefined,
});
defineSlots<{
  default(props: {}): any;
}>();

const customStyles = computed(() => ({
  ...(props.width && {
    width: typeof props.width === 'string' ? props.width : `${props.width}px`,
  }),
  ...(props.height && {
    height: typeof props.height === 'string' ? props.height : `${props.height}px`,
  }),
  ...(props.styles && typeof props.styles === 'object' && props.styles),
}));
</script>

<template>
  <component
    :is="as"
    class="skeleton"
    :class="{
      [`skeleton--${variant}`]: true,
      [`skeleton--${theme}`]: true,
    }"
    :style="customStyles"
  >
    <slot />
  </component>
</template>

<style lang="scss" scoped>
.skeleton {
  display: block;

  height: auto;
  width: 100%;

  animation: pulsate 1.5s ease-in-out 0.5s infinite;

  &--light {
    background-color: rgba(0, 0, 0, 0.11);
  }

  &--dark {
    background-color: rgba(255, 255, 255, 0.13);
  }

  &--text {
    height: fit-content;

    border-top-left-radius: 0.25rem 0.4188rem;
    border-top-right-radius: 0.25rem 0.4188rem;
    border-bottom-right-radius: 0.25rem 0.4188rem;
    border-bottom-left-radius: 0.25rem 0.4188rem;

    &:empty::before {
      content: '\00a0';
    }
  }

  &--circular {
    border-radius: 50%;
  }

  &--rounded {
    border-radius: 0.75rem;
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
</style>
